import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BizUserDetails, User } from 'src/app/models/user';
import { Area } from 'src/app/models/area';


@Injectable({
  providedIn: 'root'
})
export class StateService {

  public lgScreen$ = new ReplaySubject<boolean>(1);
  public user$ = new BehaviorSubject<User>({});
  public area$ = new BehaviorSubject<Area | undefined>(undefined);
  public userDetails$ = new BehaviorSubject<BizUserDetails>({})
  public message$ = new ReplaySubject<{ text: string, type: string }>(1);

  constructor(private responsive: BreakpointObserver,
    private http: HttpClient) {
    this.responsive.observe('(min-width: 1200px)')
      .subscribe(result => {
        if (result.matches) {
          this.lgScreen$.next(true);
        } else {
          this.lgScreen$.next(false);
        }
      });
  }
}