export const environment = {
  production: false,
  API: "https://be.devel.tourismapp.it/",
  TREEQL: "https://be.devel.tourismapp.it/",
  IMAGES: "https://images.devel.tourismapp.it/",
  FILES: "https://files.devel.tourismapp.it/",
  MESSAGES: {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'danger',
    LOG: 'dark'
  },
  APP_LOGO: "/assets/logo.png",
  SUPERTOKENS: {
    ENABLE_DEBUG_MODE: true,
    APP_INFO: {
      APP_NAME: "Tourismapp",
      API_DOMAIN: "https://be.devel.tourismapp.it",
      API_BASE_PATH: "auth/",
      WEBSITE_DOMAIN: "https://biz.devel.tourismapp.it",
      WEBSITE_BASE_PATH: "auth",
    },
    CLIENT_TYPE: "tourimsmapp-web",
    RECIPE_LIST: {
      REDIRECT: {
        NEW_USER: "/home/account",
        EXISTING_USER: "/home",
        DEFAULT: "/home"
      }
    },
    STYLE: {
      PALETTE_PRIMARY: "110, 177, 85",
      PALETTE_PRIMARY_BORDER: "63, 120, 56",
    },
    LANGUAGE: {
      DEFAULT: "it",
    }
  }
};